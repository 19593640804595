<template>
  <div class="settlement-search-container">
    <el-date-picker
      v-model="months"
      placement="bottom-start"
      type="monthrange"
      range-separator="至"
      start-placeholder="开始月份"
      value-format="yyyy-MM"
      @change="changeMonths"
      :clearable="false"
      end-placeholder="结束月份"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <a-select
      v-if="+isAdmin !== 2"
      class="search_item"
      :allowClear="true"
      placeholder="请选择媒体账号"
      v-model="searchquery.userId"
      @change="handleAutoSearch"
    >
      <a-select-option v-for="item in userList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>
    <a-select
      class="search_item"
      v-if="+isAdmin !== 2"
      :allowClear="true"
      placeholder="请选择结算状态"
      v-model="searchquery.sts"
      @change="handleAutoSearch"
    >
      <a-select-option :value="1">待同步</a-select-option>
      <a-select-option :value="2">待确认</a-select-option>
      <a-select-option :value="3">已确认</a-select-option>
    </a-select>
    <a-select
      class="search_item"
      :allowClear="true"
      placeholder="请选择支付进度状态"
      v-model="searchquery.progress"
      @change="handleAutoSearch"
    >
      <a-select-option :value="1">发票待提交</a-select-option>
      <a-select-option :value="2">发票审核中</a-select-option>
      <a-select-option :value="3">付款中</a-select-option>
      <a-select-option :value="4">已付款</a-select-option>
    </a-select>
    <div class="search_item input" v-if="+isAdmin !== 2">
      <a-input
        placeholder="请输入公司或者媒体账号查询"
        allowClear
        v-model.trim="searchquery.name"
        @input="changeInputValue"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { getMediaUserList } from '@/api/financialManagementA'
export default {
  data () {
    return {
      months: [],
      searchquery: {
        dateStart: '',
        dateEnd: '',
        userId: undefined,
        sts: undefined,
        progress: undefined,
        name: undefined
      },
      timer: null,
      userList: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      isAdmin: (state) => state.user.isAdmin,
      title: (state) => state.autoweb.title
    })
  },
  async created () {
    // 初始化时间
    await this.initMonth(2, 0)
    await this.getMediaUserList()
    // 进入时搜索
    await this.handleAutoSearch()
  },
  methods: {
    async getMediaUserList () {
      const resp = await getMediaUserList()
      this.userList = resp.data || []
    },
    initMonth (start, end) {
      const startMonth = moment().add(-start, 'M').format('YYYY-MM')
      const endMonth = moment().add(-end, 'M').format('YYYY-MM')
      this.months = [startMonth, endMonth]
      this.searchquery.dateEnd = endMonth
      this.searchquery.dateStart = startMonth
    },
    changeInputValue () {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    },
    changeMonths (e) {
      this.searchquery.dateStart = e[0]
      this.searchquery.dateEnd = e[1]
      this.handleAutoSearch()
    }
  }
}
</script>

<style lang="less">
.el-month-table td.start-date .cell,
.el-month-table td.end-date .cell {
  background-color: @primary-color !important;
}
.el-month-table td.today .cell {
  color: @primary-color;
}
.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: @primary-color;
}
.settlement-search-container {
  .el-range-editor .el-range-input {
    margin: 0 0 0 20px;
  }
}
</style>

<style lang="less">
.settlement-search-container {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  .search_item {
    width: 220px;
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
